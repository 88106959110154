import React from 'react';
import styled from '@emotion/styled';

import { Layout, Wrapper } from 'components/layout';
import SEO from 'components/seo';
import spaceLostImg from 'images/space-lost.jpg';

const StyledWrapper = styled(Wrapper)`
  min-height: 25rem;
  background-image: url(${spaceLostImg});
  background-size: auto 25rem;
  background-position: top right 2rem;
  background-repeat: no-repeat;

  @media (max-width: 719px) {
    background-position: top center;
    background-size: auto 14rem;
    padding-top: 16rem;
    min-height: auto;
  }
`;

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Page not found" path="/404/" />
    <StyledWrapper>
      <h1>404 - Page not found</h1>
      <p>Looks like you're lost...</p>
    </StyledWrapper>
  </Layout>
)

export default NotFoundPage;
